import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { convertHexToRgba } from 'styles/helpers'
import useDarkMode from 'use-dark-mode'

const ActionButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width ? `${props.width}px` : '99px')};
  height: ${(props) => (props.height ? `${props.height}px` : '48px')};
  padding: 0 20px;
  background: ${(props) => props.theme.colors.ELECTRIC_BLUE};
  border-radius: 12px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  color: ${(props) => props.theme.colors.ALMOST_WHITE};
  transition: all 0.2s;
  cursor: pointer;
  gap: 12px;
  padding: 0 16px;
  border-radius: 8px;
  font-size: 13px;

  &:hover {
    background: ${(props) => props.theme.colors.VIBRANT_BLUE};
  }
`

const CancelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${(props) => (props.width ? `${props.width}px` : '99px')};
  height: ${(props) => (props.height ? `${props.height}px` : '48px')};
  background: ${(props) =>
      props.$darkMode
          ? props.theme.colors.SATIN_BLACK
          : props.theme.colors.ALMOST_WHITE};
  border-radius: 8px;
  box-shadow: 0 0 0 2px ${(props) =>
      props.$darkMode
          ? props.theme.colors.BLUE_GRAY
          : props.theme.colors.LIGHT_GRAY}
    inset;
  transition: all 0.2s;
  font-size: 15px;
  font-weight: ${(props) => props.theme.fontweight.BOLD};
  line-height: 24px;
  letter-spacing: -0.01em;
  color: ${(props) =>
      props.$darkMode
          ? props.theme.colors.ALMOST_WHITE
          : props.theme.colors.SATIN_BLACK};

  &:hover {
    box-shadow: 0 0 0 2px
      ${(props) =>
          props.$darkMode
              ? props.theme.colors.ALMOST_WHITE
              : props.theme.colors.SATIN_BLACK} inset;
  }
`

const DisabledButton = styled(ActionButton)`
    background: ${(props) =>
        convertHexToRgba(props.theme.colors.ELECTRIC_BLUE, 0.5)};
    &:hover {
        background: ${(props) =>
            convertHexToRgba(props.theme.colors.ELECTRIC_BLUE, 0.5)};
    }
`

const Button = ({
    children,
    variant,
    width,
    height,
    onClick,
    debounceInterval = 0,
    ...other
}) => {
    const darkMode = useDarkMode(false)
    const [isDebouncing, setIsDebouncing] = useState(false)

    const handleClick = useCallback(
        async (event) => {
            if (isDebouncing) {
                return // do nothing
            }
            setIsDebouncing(true)
            try {
                // Call the original onClick handler if it exists
                if (onClick) {
                    await onClick(event)
                }
            } finally {
                // Reset debounce after 2 seconds
                setTimeout(() => {
                    setIsDebouncing(false)
                }, debounceInterval)
            }
        },
        [isDebouncing, onClick, debounceInterval],
    )

    return (
        <>
            {variant === 'primary' ? (
                <ActionButton
                    width={width}
                    height={height}
                    onClick={handleClick}
                    disabled={isDebouncing}
                    {...other}
                >
                    {children}
                </ActionButton>
            ) : variant === 'disabled' ? (
                <DisabledButton
                    width={width}
                    height={height}
                    disabled='true'
                    {...other}
                >
                    {children}
                </DisabledButton>
            ) : (
                <CancelButton
                    width={width}
                    height={height}
                    onClick={handleClick}
                    disabled={isDebouncing}
                    $darkMode={darkMode.value}
                    {...other}
                >
                    {children}
                </CancelButton>
            )}
        </>
    )
}

export default Button
